<template>
    <div class="content" :class="{'hui': resData.ishui == 'Y'}">
         <div class="header-box">
            <div class="header-div">
                <div class="header-top">
                    <img src="../../../public/static/jr/logo2.png" alt="" class="logo">
                </div>
            </div>
         </div>
        <div class="top-img">
             <div class="banner-img" v-for="(item,index) in banner" :key="index">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="content-1200">
            <div class="map-wrap" v-for="(val,i) in nav" :key="i">
                <p class="map-title" @click="tiao(val.title,val.id,'110'),tiao2(val.title,val.id,'479','开放大学'),tiao3(val.title,val.id,'465','党建之窗'),oldJr(val.title,val.tzurl)">{{val.title}}</p>
                <ul class="map-ul">
                    <li v-for="(item,index) in val.stypes" :key="index"  @click="hrefUrl(item.tzurl,item.id,item.pid,item.title)">{{item.title}}</li>
                </ul>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from './components/footer'
import JrApi from '../../lvya-common/src/api/eos/jr'
export default {
    components: {
        Footer
    },
    data() {
        return {
            nav: [],
            banner: '',
            resData: '',
        }
    },
    created() {
        this.HF()
        this.Banner()
        this.Nav()
    },
    methods: {
         hrefUrl(url, id, pid, tit) {
            if (url == '' || url == null) {
                this.$router.push({ path: '/indexList', query: { id, pid, tit }})
            } else {
                this.$router.push({ path: url, query: { id, tit }})
            }
        },
         // banner图
        async Banner() {
            const resData = await JrApi.Banner()
            this.banner = resData
        },
        async Nav() {
            const resData = await JrApi.Nav()
            this.nav = resData
        },
        async HF() {
            const resData = await JrApi.HF()
            this.resData = resData
        },
        tiao(tit, id, pid) {
            if (tit == '项目创建') {
                this.$router.push({ path: '/projectCreation', query: { id, pid }})
            }
        },
        tiao2(tit1, id, pid, tit) {
            if (tit1 == '开放大学') {
                this.$router.push({ path: '/subpage', query: { id, pid, tit }})
            }
        },
        tiao3(tit1, id, pid, tit) {
            if (tit1 == '党建之窗') {
                this.$router.push({ path: '/subpage', query: { id, pid, tit }})
            }
        },
        oldJr(tit1, url) {
            if (tit1 == '旧版网站') {
                window.open(url, '_blank')
            }
        },
    },
    mounted() {
        this.Nav()
    }
}
</script>

<style scope>
.hui{
  -webkit-filter:grayscale(100%)
}
.header-box{
    width: 100%;
    background: #fff;
}
.header-div{
    width: 1200px;
    margin: 0 auto;
}
.header-top{
    height: 120px;
    display: flex;
}
.header-top img{
    width: 500px;
    height: 90px;
    margin-top: 10px;
}
.header-top p{
    font-size: 26px;
    color: #ec1d25;
    font-family: qigong;
    font-weight: 600;
}
.header-top p span{
    width: 8px;
    height: 8px;
    background: #ec1d25;
    border-radius: 50%;
    display: inline-block;
    margin: 30px 15px 0 15px;
}
.content {
  width: 100%;
  background: #fff;
}
.top-img{
  width: 100%;
  height: 410px;
  min-width: 1200px;
}
.banner-img {
    width: 100%;
    height: 410px;
    min-width: 1200px;
    background: url(../../../public/static/jr/banner2.png) center top no-repeat;
    /* background-size: 100% 100%; */
}
.top-img img{
    width: 100%;
    height: 100%;
}
.content-1200{
    width: 1200px;
    min-height: 400px;
    margin: 20px auto;
}
.map-wrap{
    width: 100%;
}
.map-title{
    color: #881f26;
    border-bottom: 1px dashed #ccc;
    padding-inline-start: 10px;
    line-height: 40px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    cursor: pointer;
}
.map-ul{
    width: 100%;
    display: flex;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 10px;
}
.map-ul li{
    width: 100px;
    text-align: left;
    cursor: pointer;
    line-height: 50px;
}
</style>
